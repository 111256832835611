import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"

import Button from "@bit/azheng.joshua-tree.button"
import ButtonGroup from "@bit/azheng.joshua-tree.button-group"
import ImageMeta from "../components/ImageMeta"

import PaymentButton from "../components/PaymentButton"

import AppendScript from "../components/AppendScript"

const makeAPayment = props => {
  // const scriptsOld = [
  //     {
  //         name: "Ann Arbor",
  //         script: "https://www.convergepay.com/hosted-payments/buy_button_script/626164797a78514e5347796f76446d6c523663766f774141415850592f333473"
  //     },
  //     {
  //         name: "Plymouth",
  //         script: "https://www.convergepay.com/hosted-payments/buy_button_script/464c4d326c36644c54742b44454b4954515378303667414141585046486e4773"
  //     },
  //     {
  //         name: "Adrian",
  //         script: "https://www.convergepay.com/hosted-payments/buy_button_script/49646536564c53465439476f31715064576d4c65497741414158504647385544"
  //     },
  //     {
  //         name: "Dexter",
  //         script: "https://www.convergepay.com/hosted-payments/buy_button_script/6f474c2b2b565677546e43546d3839566e4a37654967414141585046494b3859"
  //     },
  // ]

  const scripts = [
    {
      name: "Ann Arbor",
      script: "/payment-ann-arbor.js",
      shortName: "ann"
    },
    {
      name: "Plymouth",
      script: "/payment-plymouth.js",
      shortName: "plymouth"
    },
    {
      name: "Adrian",
      script: "/payment-adrian.js",
      shortName: "adrian"
    },
    {
      name: "Dexter",
      script: "/payment-dexter.js",
      shortName: "dexter"
    }
  ]

  // Current payment button work around
  // go to location script url above
  // find "transactionToken"
  // add token to correct location variable
  // const annArborToken = "badyzxQNSGyovDmlR6cvowAAAXPY/34s";
  // const plymouthToken = "FLM2l6dLTt+DEKITQSx06gAAAXPFHnGs";
  // const adrianToken = "Ide6VLSFT9Go1qPdWmLeIwAAAXPFG8UD";
  // const dexterToken = "oGL++VVwTnCTm89VnJ7eIgAAAXPFIK8Y";

  //https://www.convergepay.com/hosted-payments?ssl_txn_auth_token=8fhwUSQ+RryXzTdfTt4AQwAAAYiccIlz

  const post = props.data.allUniquePagesJson.nodes[0]
  const language = "en"

  return (
    <SharedStateProvider>
      <Layout language={language}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={props.location.pathname}
        />

        <div className="columns top-section color-back">
          <div className="column is-4"></div>
          <div className="column">
            <h1 style={{ marginTop: 0, marginBottom: 0 }}>{post.heading}</h1>
          </div>
          <div className="column is-4"></div>
        </div>

        <div className="columns make-a-payment">
          <div className="column is-2"></div>
          <div className="column">
            <div className="body-section make-payment-section">
              <h4>Payment Made Easy</h4>
              <h3>Pay Online</h3>

              <p>
                Making a payment to Michigan Oral Surgeons is now easier than
                ever. If you have an account, you can make a payment online by
                clicking on the button below.
              </p>

              <ButtonGroup>
                {scripts.map((location, i) => (
                  <PaymentButton
                    key={location.name}
                    id={`pay-location-${location.name}`}
                    buttonText={"Pay " + location.name}
                    script={location.script}
                    shortName={location.shortName}
                  />
                ))}
              </ButtonGroup>
            </div>

            <div className="body-section make-payment-section">
              <h4>Other Payment Options</h4>

              <ImageMeta
                cloudName="nuvolum"
                publicId="MOS/DEV/Care-Credit-logo"
                data-src={`https://res.cloudinary.com/nuvolum/image/upload/c_scale,f_auto,q_80/v1/MOS/DEV/Care-Credit-logo.png`}
                responsive
                className="make-a-payment-image"
              />

              <p>
                For our CareCredit<sup>®</sup> patients, please click one of the
                buttons below the CareCredit icon to be directed to the payment
                portal for each office.
              </p>

              <ButtonGroup noReverse>
                <Button
                  contained
                  buttonText="Pay Ann Arbor"
                  href="https://www.carecredit.com/Pay/PHQ426"
                  external
                />
                <Button
                  contained
                  buttonText="Pay Plymouth"
                  href="https://www.carecredit.com/Pay/DCX693"
                  external
                />
                <Button
                  contained
                  buttonText="Pay Adrian"
                  href="https://www.carecredit.com/Pay/557QZK"
                  external
                />
                <Button
                  contained
                  buttonText="Pay Dexter"
                  href="https://www.carecredit.com/Pay/453KWH"
                  external
                />
              </ButtonGroup>
            </div>
          </div>
          <div className="column is-1"></div>
          <div className="column is-5 body-section make-payment-sidebar">
            <h6>Questions?</h6>
            <h5>We Are Here To Help</h5>

            <p>
              If you have questions about your account, please contact any of
              our offices in Ann Arbor, Plymouth, Adrian, and Dexter. Our
              billing department will be happy to assist you.
            </p>

            <Button contained buttonText="Contact Us" href="/contact-us/" />
          </div>
          <div className="column is-1"></div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allUniquePagesJson(filter: { title: { eq: "make-a-payment/" } }) {
      nodes {
        heading
        metaDescription
        metaTitle
        searchHeading
      }
    }
  }
`

export default makeAPayment
